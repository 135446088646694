:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */   
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1500px;
    --fixed-header-height: 80px;
   
    /* Slick */
    --slick-slider-height: 75vh;
    
    /* change settings for headings */
    --text-color: #222;
    --body-bg: #fff;
    --main-color: #f5f5f5;
    --accent-color: #222443;
    --effect-color: #b55433;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: var(--text-color);
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-li-a-padding: 10px 15px;
    --link-color: var(--accent-color);
    --link-hover-color: var(--effect-color);

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Quicksand, Helvetica, Arial, sans-serif;
    --alternative-font-family: PlayfairDisplay, Helvetica, Arial, sans-serif;  
}

@import "/cssmodules/normalize.css";
@import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Quicksand.css";
@import "/fontmodules/PlayfairDisplay.css";
/*@import "/cssmodules/animate.css";*/

/*--------------------------------------------
General
---------------------------------------------*/

img {
    max-width: 100%;
    height: auto;
}

figure {
    margin: 0;
    padding: 0
}


.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    /*background: url('/theme/intendit/graphics/bg.png'); */
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h2, h3, h4 {
    font-family: var(--base-font-family);
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    font-weight: 600;
    margin-left: 1rem !important;
}

.tjanster h1 {
    margin-left: 3rem !important;
}

.container h1 {
    /*margin: 2rem 0.8rem; */
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {
    background: var(--header-bg-color);
    position: absolute;
    width: 100%;
}

.homepage header { 
    background: none;
}

.homepage main {
    margin-top: 0px;
}

main {
    margin-top: 250px;
}

header .container {
    padding :0 0.5em;
}

header a {
    text-decoration: none;
}

.logo {
    padding: 0;
}

.logo a {
    font-size: 0;
}

.logo img {
    max-width: 280px;
    height: auto;
}

.menu li {
    margin:0;
}

.menu li a {
    font-family: var(--base-font-family);
    padding: var(--menu-li-a-padding);
    color: var(--menu-text-color); 
    text-transform: uppercase;
    font-size: 1.2rem;
}

.menu li a:hover, .menu .active a {
    color: var(--effect-color); 
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a { 
    margin: 0; 
    background: var(--main-color);
    color: #222;
}

.menu.menu .sub-menu-items li a:hover { 
    background: var(--accent-color); 
    color: #fff;
}


/**** Leftmenu ****/

.submenu ul {
    margin-top: 2.8rem;
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
} 

.submenu ul li a  {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}
.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
     position: inherit; 
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-top: -10px;
    -webkit-transform: translate(0,-50%);
    /* transform: translate(0,-50%); */
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/
.intropic {
    height: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
}

.block-1   {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
}

.textslider {
    position:absolute;
    top:50vh;
    left:50%;
}

.textslider .container {
    text-align: right;
}

.textslider-inner {
    text-align: right;
    max-width: 400px;
    width: 100%;
    margin: 0;
    color: var(--accent-color);
    font-size: 3.5rem;
    text-shadow: 1px 1px 2px rgba(0,0,0,0);
    font-family: var(--alternative-font-family);
}

.textslider-item {
    font-weight: 600;
}

/** arrows **/
.topslider .slick-prev, .topslider .slick-next {
    width:auto;
    height:auto;
}

.topslider .slick-next {
    right:50px;
    z-index:100;
}

.topslider .slick-prev {
    left:50px;
    z-index:100;
}

.topslider .slick-next, .topslider .slick-prev {
    opacity: 1;
}

.topslider .slick-next:hover, .topslider .slick-prev:hover {
    border:none;
}

.topslider .slick-next:before, .topslider .slick-prev:before {
    transition: 0.8s ease;
    background:rgba(255,255,255,0.7);
    padding: 1.5em;
    font-family: Roboto;
    line-height: normal;
    color: #222;
}

.topslider .slick-next:hover:before, .topslider .slick-prev:hover:before {
    background: white;
    color: black;
}

.topslider .slick-next:before {
    content:"»";
}

.topslider .slick-prev:before {
    content:"«";
}

/* Content-startpage | Blocks
---------------------------------------------------------*/


/********* scroll *********/
.scrolldown {
    position:absolute;
    left:0;
    right:0;
    bottom:30px;
    z-index:100;
    width:100%;
    color:red;
}

.scrolldown .container {
    text-align:center;
}

.scrolldown .container a {
    display:inline-block;
}

.scrolldown .container a svg.fa-icon {
    height:auto;
    width:6em;  
}

.scrolldown .container a svg.fa-icon:hover {
    fill: var(--effect-color);
    transition: 0.5s ease-in 0s;
}


.intropic {
    height: 100%;
    width: 100%;
}

.block-2  {
    margin: 0 auto;
    text-align: center;
    background: var(--accent-color);
    color: #eee;  
    padding: 3rem;
}


/* BOXAR
-------------------------------------------------*/

.boxlogo {
    background: rgba(181,84,51,.9);
}

.boxlogo img {
    max-width: 200px;
    margin: auto;
}

.boxlogo-inner {
    margin: auto;
}
.box-block {
    background: url('/theme/intendit/graphics/boxbg.jpg');
}

.fa-icon {
    fill: #fff;
    padding: 10px;
    height: 50px;
    width: auto;
    display: block;
    margin: 0 auto;
}

.boxwrapper:nth-child(even) .fa-icon {
    fill: #777;
}

.boxes {
    text-decoration: none;
    color: #fff;
}

.boxes a:hover {
    color: #ccc;
}

.boxbutton, button, input[type=reset], input[type=submit] {
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 0.5rem;
}

.boxwrapper {
    min-height: 35vh;
    background-size: cover;
    background-position:center;
    perspective: 1000px;
}

.boxwrapper:nth-child(odd) {
    background: rgba(34,36,67,.7);
}

.boxwrapper:nth-child(even) {
    background: rgba(255,255,255,.7);
    color: #000;
}

.boxes:nth-child(even) {
    color: #000;
}


.boxwrapper h2, .boxwrapper h2 {
    font-family: var(--base-font-family);
    text-transform: uppercase;
    font-weight: 100;
}

.boxes .boxwrapper .card {
    transition: transform .65s ease-in 0s;
    transform-style: preserve-3d;
}

.boxes .boxwrapper:hover .card {
    transform: rotateY(180deg);
    backface-visibility: hidden;
}

.boxes .card {
    position: relative;
    min-height: 140px;
    width: 100%;
    text-align: center;
}

.boxes .front, .back {
    backface-visibility: hidden;
}

.boxes .front {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.boxes .back {
    transform: rotateY(180deg);
}


/* REFERENSER slick
--------------------------------------------------------*/

.ref-block-outer {    
} 

.ref-block-outer h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-top: 0;
    font-family: var(--alternative-font-family);
}

.ref-block {
    text-align: center;
    /*background: var(--effect-color);
    color: #fff;
    font-size: 1.2rem;
    padding: 3rem;*/
} 

.ref-item {
    font-style: italic;
    min-height: 700px;
    max-width: 80%;
    margin: 0 auto;
} 


/** arrows **/

.slick-ref .slick-next:before {
    content:"";
}

.slick-ref .slick-prev:before {
    content:"";
}

.slick-ref .slick-prev, .slick-ref .slick-next {
    width:auto;
    height:auto;
}

.slick-ref .slick-next {
    right: 45%;
    z-index: 100;
    top: 97%;
}

.slick-ref .slick-prev {
    left: 45%;
    z-index:100;
    top: 97%;
}

.slick-next .slick-arrow {
    display: none;
    visibility: hidden;
    content: " ";
}

.slick-ref .slick-next, .slick-ref .slick-prev {
    opacity: 1;
}

.slick-ref .slick-next:before, .slick-ref .slick-prev:before {
    transition: 0.8s ease;
    background: none;
    padding: 1.5em;
}

.slick-ref .slick-next:hover:before {
    background: url('/theme/intendit/graphics/r-arrow.png');
    background-repeat: no-repeat !important;
}
.slick-ref .slick-prev:hover:before {
    background: url('/theme/intendit/graphics/l-arrow.png');
    background-repeat: no-repeat !important;
}

.slick-ref .slick-next:before {
    background: url('/theme/intendit/graphics/r-arrow.png');
    background-repeat: no-repeat !important;
}

.slick-ref .slick-prev:before {
    background: url('/theme/intendit/graphics/l-arrow.png');
    background-repeat: no-repeat !important;
}


/* listing items
---------------------------------------------------------*/

.tjanster-heading {
    background: var(--main-color)
}

.tjanster .fa-icon {
    fill: #222;
    margin-top: 20px;
}

.tjanster h2 {
    text-align: center;
}

hr {
    border: 0;
    height: 1px;
    background: #ccc;
    background-image: linear-gradient(to right, #eee, #ccc, #eee); 
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.content.margin > h1, .content.margin > .art-img {
    margin-left:0 ;
}

.content h1 {
    margin-bottom:0;
}

.art-content p:first-of-type {
    margin-top:0;
}

/*tabs*/
.tabs, .tab-menu {position:relative; overflow:hidden;}

a.tab-menuitem {
    display: inline-block; 
    padding: 0.5em 1.5em; 
    margin: 0 5px 5px 0; 
    font-size:1.1em; 
    text-decoration: none;
    border-bottom: 1px solid #ccc;
    min-width: 340px;
}

a.tab-menuitem.active {

    color: var(--effect-color);
}

.tab-main {
    margin-bottom: 1em; 
    padding-bottom:1em; 
}

[data-tabname] { opacity: 0; position: absolute; top: 0px; z-index: -1; transition:opacity 0.5s ease-in 0s;}
[data-tabname].active {opacity: 1; z-index: 5; position: relative; }

.tabs a.readmore {background:#e4e4e4; border-radius: 3px; max-width: 80px; padding: 0.5em 1em; text-align: center;}


/* Contact
---------------------------------------------------------*/

.contact-block-l, .contact-block-2 {
}

.kontakt svg {
    margin-right: 0.5rem;
}

.contact-block {
    background: url('/theme/intendit/graphics/shattered.png');
    padding-top: 2rem;
}

.contact-block-img img {
    max-width: 360px;
    height: auto;
}

.kontakt .boltform {
    background: #fff;
}


/* Footer
---------------------------------------------------------*/

footer {
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
}

footer a {
    text-decoration:none;
}

footer svg.fa-icon {
    fill: var(--footer-text-color);
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
}

footer svg.fa-icon:hover {
    fill: var(--accent-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background: var(--accent-color);
    padding: 0.5em 0;
    text-align: right;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
     color: #444992;
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/
.contactform .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.contactform .boltforms-row:nth-child(n+4) {
    width: 100%;
}

.contactform {
    /*background: rgba(34,36,67,.7);*/
    margin: 2rem 0;
}

.contactform input, .contactform textarea {
    width: 100%;
    background: none;
    padding: var(--padding);
    margin: var(--margin);
    color: #222;
}

.contactform button {
    background: var(--accent-color);
    color: #fff;
    border: none;
    margin: 0;
    width: 20%;
    float: right;
    margin-bottom: 1rem;
    border-radius: 0;
}

.contactform button:hover {
    background: var(--effect-color);
    color: #fff;
    transition: ease .65s
}

.contactform .contactform-row label.required:after {
    content: " *";
    color: #fff;
}


input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=file], input[type=month], input[type=number], input[type=password], input[type=phone], input[type=range], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], select, textarea {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}


/* Media Queries
---------------------------------------------------------*/

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    .textslider-inner {
        font-size: 2.5rem;
        text-align: center;
    }
    
    .contactform .boltforms-row {
        width: 100%;
    }
    
    .contactform button {
        width: 100%;
    }
    
    .menu li a, .menu li li a {
        background: var(--main-color);
        width: 100%;
        margin: 0;
    }
    .menu li, .menu li ul, .menu li:hover ul {
        margin: 0 !important;
    }
    
    a.tab-menuitem {
        display: block;
    }
    
    .slick-ref .slick-next {
        right: 40% !important;
    }
    
    .slick-ref .slick-prev {
        left: 40% !important;
    }
    
    .contact-block-img img {
        max-width: 60%;
        height: auto;
        text-align: center;
    }
        #menu-icon span, #menu-icon:after, #menu-icon:before {
        border: 1px solid var(--accent-color) !important;
    }
}

@media (max-width: 1300px) {
    
    .intropic {
        background-position: 10% 80%;
    }
}


@media (max-height: 900px) {
    
    .scrolldown {
        display: none;
    }
}
